@font-face {
  font-family: Declaration;
  src: url("./assets/fonts/Declaration.ttf");
}
@font-face {
  font-family: Prosto;
  src: url("./assets/fonts/Prosto.otf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;
